import React, { useState } from 'react'
import { withTheme } from 'theming'
import EditMenu from '../editMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { isEmpty } from 'lodash'
import useTemplateHook from '../templateHook'
import { getExternalLink } from '../templateFunctions'
import EmailIconComponent from '../imgComponents/emailIcon'
import PhoneIconComponent from '../imgComponents/phoneIcon'

const ParallelFooter = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onDelete,
  theme,
  onSwap,
}) => {
  library.add(fab)
  const { data, id } = componentData
  const { customNavigate, getTemplateImage } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')
  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey) => {
    if (!data?.style_config) {
      return ''
    }

    let tempArr = data?.style_config[parentKey].data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? tempArr[0].value : ''
  }

  return (
    <footer
      className={`relative z-20 w-full ${isEdit && hoverState && 'component-hover-class'}`}
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color')
          ? getStyleValue('component_background', 'primary_color')
          : theme.c5,
      }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={handleModal}
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div
        className={`md:flex md:items-center md:justify-between z-50 w-full h-88 md:h-32 sm:p-16 p-6`}
        style={{
          backgroundColor: getStyleValue('component_background', 'secondary_color')
            ? getStyleValue('component_background', 'secondary_color')
            : theme.c6,
        }}
      >
        <div className="flex justify-center">
          <img
            src={getTemplateImage(data?.image_s3key)}
            className="h-12 mr-10 cursor-pointer"
            onClick={() => customNavigate('DIRECT', '/home', isEdit)}
            alt="logo"
          />
        </div>
        <div className=" md:max-w-md lg:col-span-2 tw-text-center md:text-left">
          {data?.email_form?.enabled && (
            <>
              <span
                className={`text-xs text-center md:text-base font-normal tracking-wide ${
                  getStyleValue('email_form_title', 'font_format')
                    ? getStyleValue('email_form_title', 'font_format')
                    : ''
                }`}
                style={{
                  color: getStyleValue('email_form_title', 'color')
                    ? getStyleValue('email_form_title', 'color')
                    : theme.c2,
                  fontFamily: getStyleValue('email_form_title', 'font')
                    ? getStyleValue('email_form_title', 'font')
                    : '',
                  textAlign: getStyleValue('email_form_title', 'alignment')
                    ? getStyleValue('email_form_title', 'alignment')
                    : '',
                }}
              >
                {data?.email_form?.title}
              </span>
              <div className="flex flex-col mt-3 md:flex-row rounded">
                <input
                  placeholder={data?.email_form?.placeholder_text}
                  required=""
                  type="text"
                  className={`flex-grow w-full h-12 px-3 mb-3 transition duration-200 shadow-sm appearance-none md:mr-0 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline`}
                  style={{
                    backgroundColor: getStyleValue('component_background', 'secondary_color')
                      ? getStyleValue('component_background', 'secondary_color')
                      : theme.c6,
                    border: `1px solid ${
                      getStyleValue('email_form_button_text', 'button_background')
                        ? getStyleValue('email_form_button_text', 'button_background')
                        : theme.c2
                    }`,
                    color: getStyleValue('email_form_placeholder', 'color')
                      ? getStyleValue('email_form_placeholder', 'color')
                      : theme.c7,
                    fontFamily: getStyleValue('email_form_placeholder', 'font')
                      ? getStyleValue('email_form_placeholder', 'font')
                      : '',
                    textAlign: getStyleValue('email_form_placeholder', 'alignment')
                      ? getStyleValue('email_form_placeholder', 'alignment')
                      : '',
                  }}
                  disabled={isEdit}
                />
                <button
                  type="submit"
                  className={`${
                    getStyleValue('email_form_button_text', 'font_format')
                      ? getStyleValue('email_form_button_text', 'font_format')
                      : ''
                  } inline-flex items-center flex-shrink-0 justify-center h-12 px-6 font-medium shadow-md`}
                  style={{
                    backgroundColor: getStyleValue('email_form_button_text', 'button_background')
                      ? getStyleValue('email_form_button_text', 'button_background')
                      : theme.c2,
                    border: `1px solid ${
                      getStyleValue('email_form_button_text', 'button_background')
                        ? getStyleValue('email_form_button_text', 'button_background')
                        : theme.c2
                    }`,
                    color: getStyleValue('email_form_button_text', 'color')
                      ? getStyleValue('email_form_button_text', 'color')
                      : theme.c5,
                    fontFamily: getStyleValue('email_form_title', 'font')
                      ? getStyleValue('email_form_title', 'font')
                      : '',
                    textAlign: getStyleValue('email_form_title', 'alignment')
                      ? getStyleValue('email_form_title', 'alignment')
                      : '',
                  }}
                  onClick={() => handleRedirect(data?.email_form?.link)}
                  disabled={isEdit}
                >
                  {data?.email_form?.button_text} <br />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="pl-1 w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                    />
                  </svg>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex justify-between flex-col md:flex-row sm:py-6 py-4">
        <div className="sm:px-16 sm:py-4 p-6">
          {!isEmpty(data?.site_map) &&
            data?.site_map?.map(
              (siteMapItem, idx) =>
                (siteMapItem.title.trim().length > 0 ||
                  siteMapItem.sub_heading_data.some((item) => item.text.trim().length > 0)) && (
                  <ul
                    className="flex flex-wrap font-medium text-center"
                    key={idx}
                    style={{ listStyle: 'none', margin: 0, padding: '12px 0' }}
                  >
                    {siteMapItem.title.length > 0 && (
                      <li
                        className={`mr-2 ${
                          getStyleValue('title_primary', 'font_format')
                            ? getStyleValue('title_primary', 'font_format')
                            : ''
                        } inline-block mr-1 sm:text-base text-[10px] font-semibold tracking-wide`}
                        style={{
                          color: getStyleValue('title_primary', 'color')
                            ? getStyleValue('title_primary', 'color')
                            : theme.c2,

                          fontFamily: getStyleValue('title_primary', 'font')
                            ? getStyleValue('title_primary', 'font')
                            : '',
                          textAlign: getStyleValue('title_primary', 'alignment')
                            ? getStyleValue('title_primary', 'alignment')
                            : '',
                        }}
                      >
                        {siteMapItem.title} |
                      </li>
                    )}

                    {!isEmpty(siteMapItem?.sub_heading_data) &&
                      siteMapItem?.sub_heading_data.map((siteSubItem, idx) => (
                        <li className="mr-2" key={idx}>
                          <span
                            className={`${
                              getStyleValue('title_primary', 'font_format')
                                ? getStyleValue('title_primary', 'font_format')
                                : ''
                            }  cursor-pointer inline-block mr-1 sm:text-base text-[10px] font-normal tracking-wide indent-7`}
                            style={{
                              textDecoration: 'none',
                              color: getStyleValue('title_secondary', 'color')
                                ? getStyleValue('title_secondary', 'color')
                                : theme.c7,

                              fontFamily: getStyleValue('title_secondary', 'font')
                                ? getStyleValue('title_secondary', 'font')
                                : '',
                              textAlign: getStyleValue('title_secondary', 'alignment')
                                ? getStyleValue('title_secondary', 'alignment')
                                : '',
                            }}
                            onClick={() => handleRedirect(siteSubItem.link)}
                          >
                            {siteSubItem.text}
                          </span>
                        </li>
                      ))}
                  </ul>
                ),
            )}
          {/* social handles */}
          <div className="flex flex-col justify-between sm:pb-0 pb-6 pt-6 md:pt-12 sm:px-0 sm:flex-row ">
            <div className="flex sm:justify-start justify-center items-center mt-4 space-x-4 sm:mt-6">
              {data?.social_handles.map((socialHandleItem, idx) => (
                <a
                  href={isEdit ? '#' : getExternalLink(`${socialHandleItem.url}`)}
                  target={isEdit ? '' : '_blank'}
                  rel="noopener noreferrer"
                  className="i0jNr selectable-text copyable-text"
                  style={{
                    textDecoration: 'none',
                    color: getStyleValue('social_media_icon', 'color')
                      ? getStyleValue('social_media_icon', 'color')
                      : theme.c2,
                  }}
                  key={idx}
                >
                  <FontAwesomeIcon
                    icon={['fab', `${socialHandleItem.name.toLowerCase()}`]}
                    size={window.matchMedia('(min-width: 768px)').matches ? '2x' : 'xl'}
                    className="mx-1"
                  />
                </a>
              ))}
            </div>
          </div>
          {/* social handles end */}
        </div>
        <div className="relative flex flex-col md:items-end items-center md:pt-6 pt-0 md:pb-0 pb-16 md:mr-16 pr-0 ">
          <p
            className="md:py-1 text-right flex items-center w-full mb-0 text-xs md:text-base"
            style={{ inlineSize: 'max-content' }}
          >
            <a
              href={isEdit ? '#' : `mailto:${data?.email}`}
              title={`mailto:${data.email}`}
              rel="noopener noreferrer"
              className="flex"
              style={{
                textDecoration: 'none',
                color: getStyleValue('email', 'color') ? getStyleValue('email', 'color') : theme.c7,
              }}
            >
              {data?.email.length > 0 && (
                <>
                  <div>
                    <EmailIconComponent
                      color={
                        getStyleValue('email', 'color') ? getStyleValue('email', 'color') : theme.c7
                      }
                    />
                  </div>
                  <span
                    style={{
                      marginLeft: '15px',
                      fontFamily: getStyleValue('email', 'font')
                        ? getStyleValue('email', 'font')
                        : '',
                      textAlign: getStyleValue('email', 'alignment')
                        ? getStyleValue('email', 'alignment')
                        : '',
                    }}
                    className={`${
                      getStyleValue('email', 'font_format')
                        ? getStyleValue('email', 'font_format')
                        : ''
                    }`}
                  >
                    {data.email}
                  </span>
                </>
              )}
            </a>
          </p>
          <p
            className="md:py-1 text-right flex items-center w-full mb-0 mt-[0.5rem] md:mt-4 text-xs md:text-base"
            style={{ inlineSize: 'max-content' }}
          >
            <a
              href={isEdit ? '#' : `tel:${data?.phone}`}
              style={{
                textDecoration: 'none',
                color: getStyleValue('phone', 'color') ? getStyleValue('phone', 'color') : theme.c7,
              }}
              className="flex"
            >
              {data?.phone.length > 0 && (
                <>
                  <div>
                    <PhoneIconComponent
                      color={
                        getStyleValue('phone', 'color') ? getStyleValue('phone', 'color') : theme.c7
                      }
                    />
                  </div>
                  <span
                    style={{
                      marginLeft: '15px',
                      fontFamily: getStyleValue('phone', 'font')
                        ? getStyleValue('phone', 'font')
                        : '',
                      textAlign: getStyleValue('phone', 'alignment')
                        ? getStyleValue('phone', 'alignment')
                        : '',
                    }}
                    className={`${
                      getStyleValue('phone', 'font_format')
                        ? getStyleValue('phone', 'font_format')
                        : ''
                    }`}
                  >
                    {data.phone}
                  </span>
                </>
              )}
            </a>
          </p>
          <p
            className={`${
              getStyleValue('copyright', 'font_format')
                ? getStyleValue('copyright', 'font_format')
                : ''
            } absolute block md:right-0 right-1/2 md:translate-x-0 translate-x-1/2 bottom-4 font-thin text-xs md:text-sm`}
            style={{
              color: getStyleValue('copyright', 'color')
                ? getStyleValue('copyright', 'color')
                : theme.c7,
              fontFamily: getStyleValue('copyright', 'font')
                ? getStyleValue('copyright', 'font')
                : '',
              textAlign: getStyleValue('copyright', 'alignment')
                ? getStyleValue('copyright', 'alignment')
                : '',
            }}
          >
            {data?.copyright_text}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default withTheme(ParallelFooter)
