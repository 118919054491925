import React, { useState } from 'react'
import EditMenu from '../editMenu'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import { isEmpty } from 'lodash'

const PassimCarve = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData
  const { customNavigate, getTemplateImage, VideoPlaceholder } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')
  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey].data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
      style={{ background: getStyleValue('component_background', 'color', theme.c1) }}
      className={` ${isEdit && hoverState && 'component-hover-class'} p-4 lg:p-8 relative`}
      onClick={() => handleRedirect(data?.link)}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={handleModal}
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onCopy={() => onCopy(id)}
        />
      )}
      <div className=" mx-auto space-y-4 md:space-y-12">
        {data?.title && (
          <h1
            style={{
              color: getStyleValue('section_title', 'color', theme.c2),
              fontFamily: getStyleValue('section_title', 'font', ''),
              textAlign: getStyleValue('section_title', 'alignment', 'center'),
            }}
            className={` uppercase text-2xl sm:text-4xl  ${getStyleValue(
              'section_title',
              'font_format',
              'font-bold',
            )}`}
          >
            {data?.title}
          </h1>
        )}

        <div
          className={`cursor-pointer flex flex-col  overflow-hidden rounded-md shadow-sm ${
            data?.aligned === 'LEFT' ? 'lg:flex-row' : 'lg:flex-row-reverse'
          } `}
        >
          <div className=" w-full md:w-1/2">
            <CustomImage
              imageUrl={getTemplateImage(data?.image_s3key)}
              imgClass="w-full"
              loaderClass="w-full h-full"
              loaderStyle={{ background: '#5A5A5A50' }}
              imgAlt={'recent story Img'}
              errorPlaceholder={VideoPlaceholder}
            />
          </div>
          <div className="flex flex-col items-center justify-center flex-1  py-[0.75rem] md:py-0 sm:p-16 ">
            <button
              type="button"
              style={{
                textDecorationColor: getStyleValue('heading1', 'color', theme.c2),
                color: getStyleValue('heading1', 'color', theme.c5),
                fontFamily: getStyleValue('heading1', 'font', ''),
                textAlign: getStyleValue('heading1', 'alignment', 'center'),
              }}
              className={`border-0 bg-transparent text-xs md:text-[20px] ${getStyleValue(
                'heading1',
                'font_format',
                'underline',
              )} `}
            >
              {data?.heading_1}
            </button>
            <h3
              style={{
                lineHeight: '120%',
                color: getStyleValue('heading2', 'color', theme.c3),
                fontFamily: getStyleValue('heading2', 'font', ''),
                textAlign: getStyleValue('heading2', 'alignment', 'center'),
              }}
              className={`text-[18px] md:text-[50px]  md:mt-4 mt-1  ${getStyleValue(
                'heading1',
                'font_format',
                'font-bold',
              )}`}
            >
              {data?.heading_2}
            </h3>
            <p
              style={{
                color: getStyleValue('paragraph', 'color', theme.c4),
                fontFamily: getStyleValue('paragraph', 'font', ''),
                textAlign: getStyleValue('paragraph', 'alignment', ''),
              }}
              className={`my-[0.5rem] md:my-6 text-[10px] md:text-[20px] ${getStyleValue(
                'paragraph',
                'font_format',
                '',
              )}`}
            >
              {data?.description}
            </p>
          </div>
        </div>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(PassimCarve)
