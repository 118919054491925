import { isEmpty } from 'lodash'
import React, { useEffect, useState, useContext } from 'react'
import { GrEmoji } from 'react-icons/gr'
import { FaRegKeyboard } from 'react-icons/fa'
import ScrollToBottom from 'react-scroll-to-bottom'
import { useTranslation } from 'react-i18next'
import Picker from 'emoji-picker-react'
import { AppContext } from '../../context/app'
import { LIVE_COMMENTS_API } from '../../apiUrls'
import useAxios from 'axios-hooks'
import { useParams } from 'react-router'
// import imageSpinner from '../../assets/image-spinner.gif'
import SendIcon from '../../assets/send-icon-blue.svg'
import moment from 'moment'
import { convertDurationLiveComment, getRandomBgGradient } from '../../helper'

let commentTimestamp = ''
const LiveComments = () => {
  const [chatStream, setChatStream] = useState([])
  const [inputText, setInputText] = useState('')
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)
  const { isLogin, setOpenLoginModal, userObj } = useContext(AppContext)
  const contentId = useParams().contentId

  const { t } = useTranslation()

  const [{ data: commentsList }, getLiveComments] = useAxios(
    {
      url: LIVE_COMMENTS_API + '/' + contentId,
      method: 'get',
    },
    { manual: true },
  )

  const [{ loading: postCommentLoading }, postCommentCall] = useAxios(
    {
      url: LIVE_COMMENTS_API + '/' + contentId,
      method: 'post',
    },
    { manual: true },
  )
  console.log(postCommentLoading)

  useEffect(() => {
    if (commentsList && commentsList?.success) {
      setChatStream((chatStream) => chatStream.concat(commentsList?.data))

      commentTimestamp =
        commentsList?.data?.length > 0
          ? commentsList?.data[commentsList?.data?.length - 1]?.timestamp
          : commentTimestamp
    }
  }, [commentsList])

  useEffect(() => {
    if (isEmpty(chatStream)) {
      getChatData().then((r) => console.log(r))
    }
  }, [])

  const getChatData = async () => {
    await getLiveComments()

    setInterval(async () => {
      await getLiveComments({
        params: {
          timestamp: commentTimestamp,
        },
      })
    }, 10000)
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputText) {
      handleSubmit().then((r) => console.log(r))
    }
  }

  const handleSubmit = async () => {
    if (!isLogin) {
      setOpenLoginModal(true)
      setInputText('')
      return
    }
    if (inputText) {
      const postRes = await postCommentCall({
        data: {
          comment: inputText,
        },
      })
      if (postRes.data?.success) {
        setInputText('')
        window.scrollTo(0, 0)
        await getLiveComments({
          params: {
            timestamp: commentTimestamp,
          },
        })
      }
    }
  }

  const onEmojiClick = (event, emojiObject) => {
    setInputText((inputText) => inputText.concat(emojiObject.emoji))
  }

  return (
    <div
      style={{
        backgroundColor: '#000000',
        borderRadius: '0 0 10px 10px',
      }}
      className={` w-full h-full   relative`}
    >
      <div
        style={{
          backgroundColor: '#000000',
          position: 'absolute',
          top: '0',
        }}
        className={` w-full  ${
          emojiPickerOpen
            ? `bottom-[230px] md:bottom-[290px] lg:bottom-[320px] xl:bottom-[350px] `
            : ' bottom-[70px] md:bottom-[90px]'
        }`}
      >
        <ScrollToBottom className="w-full h-full " scrollViewClassName="scrollbar-class">
          <div className="px-3 xl:px-5">
            {!isEmpty(chatStream) ? (
              chatStream.map((info, index) => {
                let duration = moment.duration(
                  moment().diff(moment.utc(info?.created_date).local()),
                )
                let seconds = duration.asSeconds()
                return (
                  <div className={'my-2 md:my-4 flex'} key={index}>
                    <div className="w-4 h-4 md:h-8 md:w-8 font-semibold flex items-center justify-center rounded-md relative">
                      <img
                        src={getRandomBgGradient(info.user.name)}
                        className="w-full h-full"
                        alt="getRandomBgGradient"
                      />
                      <p className="absolute">{Array.from(`${info.user.name}`.toUpperCase())[0]}</p>
                    </div>
                    <div className=" items-center ml-2 xl:ml-5 w-full">
                      <div className="flex justify-between items-center">
                        <span style={{ color: '#d5d5d5' }} className={'text-sm md:text-lg  '}>
                          {info.user.name}
                        </span>
                        <span style={{ color: '#989898' }} className={'text-xs'}>
                          {convertDurationLiveComment(seconds)}
                        </span>
                      </div>
                      <div style={{ color: '#e3e2e2' }} className="mt-1 text-xs md:text-base">
                        <p> {info.comment}</p>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div
                style={{ color: '#fff' }}
                className="h-full w-full flex justify-center items-center"
              >
                {t('no_comments')}
              </div>
            )}
          </div>
        </ScrollToBottom>
      </div>
      <div
        style={{ borderTop: '0.5px solid gray' }}
        className=" w-full mt-2 px-3 xl:px-5 py-2 md:py-3 xl:py-4 absolute bottom-0"
      >
        <div className=" w-full">
          <div className="relative flex">
            <div className="w-4 h-4 md:h-8 md:w-8 font-semibold flex items-center justify-center rounded-md relative">
              <img
                src={getRandomBgGradient(userObj.name)}
                className="w-full h-full"
                alt="getRandomBgGradient"
              />
              <p className="absolute">{Array.from(`${userObj.name}`.toUpperCase())[0]}</p>
            </div>
            <div className=" w-full">
              <p className="text-white mx-2">{userObj.name}</p>
              <div className=" w-full flex mt-1 items-center">
                <input
                  type="text"
                  placeholder={`Comment`}
                  style={{
                    color: '#fff',
                    border: 'solid 0.5px #707070',
                    background: '#0c121a',
                  }}
                  className="w-full text-sm px-2 md:text-base focus:outline-none rounded-xl mx-2  "
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
                <span className=" inset-y-0 flex items-center mx-2">
                  {emojiPickerOpen ? (
                    <FaRegKeyboard
                      style={{ color: '#fff' }}
                      className="h-6 w-6 opacity-80 hover:opacity-100 cursor-pointer"
                      onClick={() => setEmojiPickerOpen(false)}
                    />
                  ) : (
                    <GrEmoji
                      style={{ color: '#fff' }}
                      className="h-6 w-6 opacity-80 hover:opacity-100 cursor-pointer"
                      onClick={() => setEmojiPickerOpen(true)}
                    />
                  )}
                </span>
                <span className="inset-y-0 flex items-center ">
                  <img
                    src={SendIcon}
                    alt="SendIcon"
                    className="h-6 w-6 cursor-pointer"
                    onClick={handleSubmit}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        {emojiPickerOpen && (
          <div className={`h-[150px] md:h-[200px] lg:h-[250px] xl:h-[250px]`}>
            <Picker
              pickerStyle={{
                width: '100%',
                height: '100%',
                marginTop: '10px',
                boxShadow: 'none',
                borderRadius: '3px',
              }}
              onEmojiClick={onEmojiClick}
              disableSearchBar={true}
              groupNames={{
                smileys_people: t('people_emoji'),
                animals_nature: t('animals_emoji'),
                food_drink: t('food_emoji'),
                travel_places: t('travel_emoji'),
                activities: t('activities_emoji'),
                objects: t('objects_emoji'),
                symbols: t('symbols_emoji'),
                flags: t('flags_emoji'),
                recently_used: t('recently_used'),
              }}
              disableAutoFocus={true}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default LiveComments
