import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import { withTheme } from 'theming'
import moment from 'moment'
import CommentInput from './commentInput'
import { isEmpty } from 'lodash'
import useAxios from 'axios-hooks'
import { POST_LIKE_URL } from '../../apiUrls'
import InComponentLoader from '../InComponentLoader'
import { getRandomBgGradient } from '../../helper'
import LikeIcon from '../../assets/imgComponents/likeIcon'
import DislikeIcon from '../../assets/imgComponents/dislikeIcon'

const CommentComponent = (props) => {
  const { comment, theme, handleLikeDislike, handleSubmitReply, handleUpdateChildLike } = props
  const [showReplies, setShowReplies] = useState(false)
  const [replyInput, setReplyInput] = useState(false)
  const { isCustomTemplate } = useContext(AppContext)

  const [{ data: likeComments, loading: likeCommentLoading }, likeCommentCall] = useAxios(
    {
      url: POST_LIKE_URL + '/comment/',
      method: 'post',
    },
    { manual: true },
  )

  const handleLikeDislikeChild = (id, like) => {
    likeCommentCall({
      url: POST_LIKE_URL + '/comment/' + id + '?isLike=' + like,
      method: 'post',
    })
  }

  const handleSubmit = (text) => {
    handleSubmitReply(text, comment._id)
    setReplyInput(false)
  }

  useEffect(() => {
    if (likeComments && likeComments.success) {
      handleUpdateChildLike(likeComments.data, comment._id)
    }
  }, [likeComments])

  return (
    <div className="comment mb-6">
      <InComponentLoader loading={likeCommentLoading} />
      <div className={'flex align-middle mt-2 mb-2 '} style={{ outline: '2px solid transparent' }}>
        <div
          style={{ color: '#060606' }}
          className="w-6 h-6 md:h-8 md:w-8 font-semibold flex items-center justify-center rounded-md relative"
        >
          <img
            src={getRandomBgGradient(comment.user.name)}
            className="w-full h-full"
            alt="getRandomBgGradient"
          />
          <p className="absolute text-sm md:text-base">
            {Array.from(`${comment.user.name}`.toUpperCase())[0]}
          </p>
        </div>
        <div className={'ml-4 w-full'}>
          <span
            style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
            className={'sm:text-[0.5rem] md:text-[0.8rem] mr-5 font-bold'}
          >
            {comment.user.name}
          </span>
          <span className="text-xs" style={{ color: isCustomTemplate ? theme.c3 : '#c4c4c4' }}>
            {moment.unix(comment.timestamp).fromNow()}
          </span>
          <div className={'mt-2'} style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}>
            <span style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}>{comment.comment}</span>
            <div className="flex gap-x-5 py-3">
              <span className="flex justify-center items-center">
                <button onClick={() => handleLikeDislike(comment._id, '1')}>
                  <LikeIcon color={isCustomTemplate ? theme.c4 : '#fff'} imgClass="h-5 w-5" />
                </button>
                <span className="text-xs ml-1">{comment.like_count}</span>
              </span>
              <span className="flex justify-center items-center">
                <button onClick={() => handleLikeDislike(comment._id, '0')}>
                  <DislikeIcon color={isCustomTemplate ? theme.c4 : '#fff'} imgClass="h-5 w-5" />
                </button>
                <span className="text-xs ml-2">{comment.dislike_count}</span>
              </span>
              <button className="text-xs font-bold" onClick={() => setReplyInput(true)}>
                Reply
              </button>
            </div>
          </div>

          {replyInput && <CommentInput theme={theme} handleSubmit={handleSubmit} />}
          {!isEmpty(comment.replies) && (
            <div
              className="flex justify-start items-center"
              style={{ outline: '2px solid transparent' }}
            >
              <button
                className={` w-8 h-8 ${showReplies && 'rotate-180'}`}
                style={{
                  clipPath: 'polygon(25% 40%, 75% 40%, 50% 60%)',
                  transition: '.5s',
                  background: '#0abcff',
                }}
                onClick={() => setShowReplies(!showReplies)}
              >
                {''}
              </button>
              <button onClick={() => setShowReplies(!showReplies)}>
                <p className="px-1 font-semibold text-[#0abcff] text-xs">
                  {comment.replies.length} replies
                </p>
              </button>
            </div>
          )}
        </div>
      </div>
      <div>
        {showReplies &&
          comment.replies.map((comment) => (
            <div className="reply" style={{ paddingLeft: '44px' }} key={comment._id}>
              <div
                className={'flex align-middle mt-2 mb-2 '}
                style={{ outline: '2px solid transparent' }}
              >
                <div
                  style={{ color: '#060606' }}
                  className="w-6 h-6 md:h-8 md:w-8 font-semibold flex items-center justify-center rounded-md relative"
                >
                  <img
                    src={getRandomBgGradient(comment.user.name)}
                    className="w-full h-full"
                    alt="getRandomBgGradient"
                  />
                  <p className="absolute text-sm md:text-base">
                    {Array.from(`${comment.user.name}`.toUpperCase())[0]}
                  </p>
                </div>
                <div className={'ml-4 w-full'}>
                  <span
                    style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                    className={'sm:text-[0.5rem] md:text-[0.8rem] mr-5 font-bold'}
                  >
                    {comment.user.name}
                  </span>
                  <span
                    style={{ color: isCustomTemplate ? theme.c3 : '#fff' }}
                    className="text-xs text-[#c4c4c4]"
                  >
                    {moment.unix(comment.timestamp).fromNow()}
                  </span>
                  <div className={'mt-2'}>
                    <span style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}>
                      {comment.comment}
                    </span>
                    <div
                      className="flex gap-x-5 py-3"
                      style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                    >
                      <span className="flex justify-center items-center">
                        <button onClick={() => handleLikeDislikeChild(comment._id, '1')}>
                          <LikeIcon
                            color={isCustomTemplate ? theme.c4 : '#fff'}
                            imgClass="h-5 w-5"
                          />
                          {/* <img src={LikeIcon} alt="" className="h-5 w-5" /> */}
                        </button>
                        <span
                          style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                          className="text-xs ml-1"
                        >
                          {comment.like_count}
                        </span>
                      </span>
                      <span className="flex justify-center items-center">
                        <button onClick={() => handleLikeDislikeChild(comment._id, '0')}>
                          <DislikeIcon
                            color={isCustomTemplate ? theme.c4 : '#fff'}
                            imgClass="h-5 w-5"
                          />
                        </button>
                        <span className="text-xs ml-2">{comment.dislike_count}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default withTheme(CommentComponent)
