import React from 'react'

const EmailIconComponent = ({ color = '#dfdada' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.164"
      height="14.431"
      viewBox="0 0 22.164 14.431"
    >
      <path
        id="Email"
        style={{ fill: color }}
        d="M29.108,35.3a.381.381,0,0,0,.02-.118V21.524a.379.379,0,0,0-.021-.119c0-.005,0-.01,0-.015a.384.384,0,0,0-.071-.118s0-.005,0-.007l0,0a.387.387,0,0,0-.114-.083l-.009-.005a.38.38,0,0,0-.131-.032H7.325a.385.385,0,0,0-.133.032l-.007,0a.381.381,0,0,0-.115.084l0,0s0,0,0,.007a.386.386,0,0,0-.071.119c0,.005,0,.01,0,.016a.379.379,0,0,0-.021.119v13.66a.382.382,0,0,0,.021.12l0,.015a.386.386,0,0,0,.072.119l0,.007.005,0a.39.39,0,0,0,.041.036l.019.015a.357.357,0,0,0,.054.03l.013.007a.386.386,0,0,0,.151.031H28.742a.382.382,0,0,0,.151-.031l.021-.011a.393.393,0,0,0,.047-.026l.022-.018a.354.354,0,0,0,.037-.033l.006-.006s0-.005,0-.008a.385.385,0,0,0,.071-.118A.141.141,0,0,0,29.108,35.3ZM7.736,22.4l6.491,5.929L7.736,34.3Zm13.3,5.639a.368.368,0,0,0-.077.057c-.008.009-.011.021-.019.031l-2.9,2.647-9.7-8.862h19.4Zm-6.241.813,2.987,2.729a.385.385,0,0,0,.52,0l2.95-2.695,6.49,5.914H8.338Zm7.03-.489L28.357,22.4V34.311Z"
        transform="translate(-6.964 -21.139)"
      />
    </svg>
  )
}

export default EmailIconComponent
