import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { AppContext } from '../../context/app'
import { SubscriptionContext } from '../../context/subscription'
import { CREATE_TRANSACTION, GET_DISCOUNTS, GET_ESTIMATE, PAYEMENT_ORGS } from '../../apiUrls'
import useAxios from 'axios-hooks'
import { isEmpty, raiseToast } from '../../helper'
import { RiCloseCircleFill } from 'react-icons/ri'
import DiscountModal from './discountModal'
import GreenTick from '../../assets/green_tick.png'
import { displayRazorpay } from './paymentSDK/razorPay'
import GatewayModal from './gatewayModal'
import handleStripe from './paymentSDK/stripe'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useTranslation } from 'react-i18next'
import handlePaystack from './paymentSDK/paystack'
import { withTheme } from 'theming'
import handlePayPal from './paymentSDK/paypal'

const PaymentPage = ({ theme }) => {
  const { setLoader, isCustomTemplate } = useContext(AppContext)
  const { t } = useTranslation()
  const { availableSubscription, redirectionUrl } = useContext(SubscriptionContext)
  const [estimateInfo, setEstimateInfo] = useState({})
  const [subscriptionInfo, setSubscriptionInfo] = useState({})
  const [estimationPayload, setEstimationPayload] = useState({})

  const navigate = useNavigate()

  const [userDiscounts, setUserDiscounts] = useState([])
  const [discountModalOpen, setDiscountModalOpen] = useState(false)

  const [verifiedPaymentOrgs, setVerifiedPaymentOrgs] = useState([])
  const [paymentGatewayOpen, setPaymentGatewayOpen] = useState(false)

  let subscriptionId = useParams().subscriptionId

  useEffect(() => {
    if (!isEmpty(availableSubscription)) {
      let subObj = availableSubscription.find((obj) => obj._id === subscriptionId)
      setSubscriptionInfo(subObj)
      getEstimation('', '')
    }
  }, [subscriptionId, availableSubscription])

  const getEstimation = (discountCode, discountId) => {
    setEstimationPayload({
      subscription_id: subscriptionId,
      discount_code: discountCode,
      discount_id: discountId,
    })
    estimateCall({
      data: {
        subscription_id: subscriptionId,
        discount_code: discountCode,
        discount_id: discountId,
      },
    })
  }

  const [{ data: paymentOrgList, loading: paymentOrgListLoading }] = useAxios({
    url: PAYEMENT_ORGS,
    method: 'get',
  })

  const [{ data: discountList, loading: discountListLoading }] = useAxios({
    url: GET_DISCOUNTS,
    method: 'get',
    params: {
      plan_id: subscriptionId,
    },
  })

  const [{ data: estimateRes, loading: estimateLoading }, estimateCall] = useAxios(
    {
      url: GET_ESTIMATE,
      method: 'post',
    },
    { manual: true },
  )
  const [{ data: createTransactionRes, loading: createTransactionLoading }, createTransactionCall] =
    useAxios(
      {
        url: CREATE_TRANSACTION,
        method: 'post',
      },
      { manual: true },
    )

  useEffect(() => {
    setLoader(
      estimateLoading || paymentOrgListLoading || discountListLoading || createTransactionLoading,
    )
  }, [estimateLoading, createTransactionLoading, paymentOrgListLoading, discountListLoading])

  useEffect(() => {
    if (estimateRes && estimateRes.success) {
      setEstimateInfo(estimateRes.data)
      if (estimateRes.data.is_valid_discount) {
        setDiscountModalOpen(false)
      } else {
        raiseToast('error', estimateRes.data.message)
      }
    }
  }, [estimateRes])

  useEffect(() => {
    if (discountList && discountList.success) {
      setUserDiscounts(
        discountList.data.filter((item) => item.applicable_subscriptions.includes(subscriptionId)),
      )
    }
  }, [discountList])

  useEffect(() => {
    if (paymentOrgList && paymentOrgList.success) {
      setVerifiedPaymentOrgs(
        paymentOrgList.data.filter((item) => item.is_account_verified === true),
      )
    }
  }, [paymentOrgList])

  useEffect(() => {
    if (createTransactionRes && createTransactionRes.success) {
      let { customer_gateway_info } = createTransactionRes.data
      let selectedOrg = verifiedPaymentOrgs.find((item) => item._id === customer_gateway_info)

      if (selectedOrg.org === 'Razorpay') {
        displayRazorpay(
          createTransactionRes.data._id,
          createTransactionRes.data.initiate_payload,
          navigate,
        )
      }
      if (selectedOrg.org === 'Stripe') {
        handleStripe(createTransactionRes.data.initiate_payload)
      }
      if (selectedOrg.org === 'Paystack') {
        handlePaystack(createTransactionRes.data.initiate_payload)
      }
      if (selectedOrg.org === 'PayPal') {
        handlePayPal(createTransactionRes.data.initiate_payload)
      }
    }
  }, [createTransactionRes])

  const validateInfo = () => {
    let isValid = true
    if (isEmpty(estimateInfo) || isEmpty(verifiedPaymentOrgs)) {
      isValid = false
    }
    return isValid
  }

  const createTransaction = (gatewayId) => {
    let data = {
      ...estimationPayload,
      customer_gateway_info_id: gatewayId,
      redirection_url: redirectionUrl,
    }
    createTransactionCall({ data })
  }

  const handlePayNow = async () => {
    if (!validateInfo()) return

    if (verifiedPaymentOrgs.length === 1) {
      createTransaction(verifiedPaymentOrgs[0]._id)
      return
    }
    setPaymentGatewayOpen(true)
  }

  return (
    <>
      <div
        className="flex flex-col items-center md:py-10"
        style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
      >
        <div
          style={{
            borderColor: isCustomTemplate ? theme.c5 : '',
            backgroundColor: isCustomTemplate ? theme.c10 : '#1b242f',
          }}
          className="w-4/5 md:w-2/4 mt-10 border p-5 rounded-xl"
        >
          <div className="md:text-2xl text-lg font-semibold flex justify-center">
            <h2>{t('price_summary')}</h2>
          </div>
          <div className="flex justify-between mt-4 md:text-xl md:mt-8">
            <p className={'text-sm md:text-lg'}>
              {subscriptionInfo?.name} {t('plan')}
            </p>
            {estimateInfo?.subscription?.amount && (
              <p className={'text-sm md:text-lg'}>
                {getSymbolFromCurrency(estimateInfo.currency)}{' '}
                {parseFloat(estimateInfo?.subscription?.amount).toFixed(2)}
              </p>
            )}
          </div>
          {estimateInfo?.is_discount_applied && (
            <div className="flex justify-between  mt-2 md:text-xl md:mt-4">
              <p className={'text-sm md:text-lg'}> {t('discount')}</p>
              {estimateInfo?.discount_amount && (
                <p className={'text-sm md:text-lg'}>{`- ${getSymbolFromCurrency(
                  estimateInfo.currency,
                )} ${parseFloat(estimateInfo?.discount_amount).toFixed(2)}`}</p>
              )}
            </div>
          )}

          {estimateInfo?.tax_amount !== 0 && (
            <div className="flex justify-between mt-1 md:text-xl md:mt-4">
              <p className={'text-sm md:text-lg'}>{t('estimated_tax')} </p>
              {estimateInfo?.tax_amount && (
                <p className={'text-sm md:text-lg'}>{`+ ${getSymbolFromCurrency(
                  estimateInfo.currency,
                )} ${parseFloat(estimateInfo?.tax_amount).toFixed(2)}`}</p>
              )}
            </div>
          )}
          <hr className="my-3 md:my-6 border-black" />
          <div className="flex justify-between font-bold mt-1 md:text-xl">
            <p className={'text-sm md:text-lg'}> {t('grand_total')}</p>
            {estimateInfo?.transaction_amount && (
              <p className={'text-sm md:text-lg'}>{`${getSymbolFromCurrency(
                estimateInfo.currency,
              )} ${parseFloat(estimateInfo?.transaction_amount).toFixed(2)}`}</p>
            )}
          </div>
          {estimateInfo?.is_discount_applied ? (
            <div className="flex items-center justify-end mt-10">
              <RiCloseCircleFill
                style={{ color: 'gray' }}
                className="w-8 h-8 text-gray-600 cursor-pointer"
                onClick={() => getEstimation('', '')}
              />
              <button
                style={{ color: '#008000', border: '1px solid #008000' }}
                className="bg-transparent ml-3 my-1 px-5 py-2 rounded-lg focus:outline-none mt-2"
              >
                {estimateInfo.discount.discount_code
                  ? estimateInfo.discount.discount_code
                  : estimateInfo.discount.name}
              </button>
              <div className="flex items-center">
                <img src={GreenTick} className="w-8 h-8 ml-3" alt="green tick" />
              </div>
            </div>
          ) : (
            <div className="flex justify-center mt-8 md:mt-12 font-bold md:text-xl">
              <button
                style={{
                  border: isCustomTemplate ? '' : '1px solid #008000',
                  color: isCustomTemplate ? theme.c7 : '#fff',
                  backgroundColor: isCustomTemplate ? theme.c5 : '',
                }}
                className="bg-transparent my-1 px-5 py-2 rounded-lg focus:outline-none "
                onClick={() => setDiscountModalOpen(true)}
              >
                {t('apply_discount_vouchers')}
              </button>
            </div>
          )}

          <div className="flex justify-center mt-10 md:mt-20 font-bold md:text-xl">
            <button
              className="bg-gradient-to-r from-pink-500 via-indigo-400 to-blue-400 text-white px-6 py-2 rounded-full my-1 focus:outline-none"
              onClick={handlePayNow}
            >
              {t('proceed_to_pay')}
            </button>
          </div>
        </div>
      </div>
      {discountModalOpen && estimateInfo && (
        <DiscountModal
          currency={getSymbolFromCurrency(estimateInfo.currency)}
          discountData={userDiscounts}
          getEstimation={getEstimation}
          onCancel={() => setDiscountModalOpen(false)}
        />
      )}
      {paymentGatewayOpen && (
        <GatewayModal
          data={verifiedPaymentOrgs}
          createTransaction={createTransaction}
          onCancel={() => setPaymentGatewayOpen(false)}
        />
      )}
    </>
  )
}

export default withTheme(PaymentPage)
