import React from 'react'

const PhoneIconComponent = ({ color = '#dfdada' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.164"
      height="22.162"
      viewBox="0 0 22.164 22.162"
    >
      <path
        id="Phone"
        style={{ fill: color }}
        d="M20.467,4.814A11.081,11.081,0,1,0,12.88,23.97a.594.594,0,0,0,0-1.189,9.892,9.892,0,1,1,9.892-9.892A9.787,9.787,0,0,1,21.7,17.375a2.137,2.137,0,0,1-2.811.909l-.315-.149c.179-.18.344-.351.466-.481a.387.387,0,0,0-.055-.576l-2.957-1.9a.44.44,0,0,0-.626.107c-.237.35-.778,1.117-.778,1.117s-1.266.682-3.5-1.558c-.034-.034-.061-.065-.094-.1s-.065-.06-.1-.094c-2.239-2.239-1.558-3.5-1.558-3.5s.767-.541,1.117-.778a.44.44,0,0,0,.107-.626l-1.9-2.957a.387.387,0,0,0-.576-.055,11.366,11.366,0,0,0-1.486,1.6A3.086,3.086,0,0,0,6.5,10.616a13.184,13.184,0,0,0,3.206,5.2c.041.041.083.077.124.117s.076.083.117.124a13.184,13.184,0,0,0,5.2,3.206,3.086,3.086,0,0,0,2.281-.128,1.3,1.3,0,0,0,.2-.135l.747.354a3.332,3.332,0,0,0,4.379-1.443,10.964,10.964,0,0,0,1.2-5.025A11.124,11.124,0,0,0,20.467,4.814Z"
        transform="translate(-1.797 -1.808)"
      />
    </svg>
  )
}

export default PhoneIconComponent
