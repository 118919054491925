import React, { useContext } from 'react'
import { withTheme } from 'theming'
// import { AppContext } from '../../context/app'
import { useTranslation } from 'react-i18next'
import { createSlug, getReleaseDate } from '../../helper'
import { useNavigate } from 'react-router'
import { isEmpty } from 'lodash'
import { AppContext } from '../../context/app'

const PlayerPageAbout = ({ theme, detailObj, contentId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isCustomTemplate } = useContext(AppContext)

  const handleCategoryClick = (id, name) => {
    const encoded = btoa(JSON.stringify({ filters: { category: [id] } }))
    navigate(`/category/${id}?category=${btoa(name)}&filters=${encoded}`)
  }

  const handleSubCategory = (id, name) => {
    const encoded = btoa(JSON.stringify({ filters: { sub_category: [id] } }))
    navigate(`/content/filter/${createSlug(name)}?filters=${encoded}`)
  }

  const handleCustomMetadata = (id, value, name) => {
    value = value.trim()
    const encoded = btoa(JSON.stringify({ filters: { custom: { [id]: [value] } } }))
    navigate(`/content/filter/${createSlug(value)}-${createSlug(name)}?filters=${encoded}`)
  }

  return (
    <div className={`z-10 w-full `} style={{ color: '#e3e2e2', marginTop: '2vw' }}>
      {!isEmpty(detailObj) && (
        <>
          <p style={{ color: isCustomTemplate ? theme.c4 : '#cecece' }} className="text-lg ">
            {detailObj?.description}
          </p>

          <div className={' text-lg'} style={{ marginTop: '2vw' }}>
            <div className={'flex'}>
              <p
                style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                className={'   text-sm md:text-lg w-4/12 lg:w-2/12'}
              >
                {t('category')}
              </p>
              <div
                style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9' }}
                className={' text-sm md:text-lg  w-8/12 lg:w-10/12 flex flex-col xl:flex-row'}
              >
                {[...detailObj?.category].map((item, index) => (
                  <p
                    key={index}
                    onClick={() => handleCategoryClick(item.id, item.name)}
                    className={'cursor-pointer hover:underline'}
                  >
                    {index !== 0 && <span className={'hidden xl:contents'}>{' , '}</span>}
                    {item.name}
                  </p>
                ))}
              </div>
            </div>

            {detailObj?.release_time && (
              <div className={'flex mt-2'}>
                <p
                  style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                  className={'   text-sm md:text-lg w-4/12 lg:w-2/12'}
                >
                  {t('release_date')}
                </p>
                <p
                  style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9' }}
                  className={' text-sm md:text-lg  w-8/12 lg:w-10/12 flex flex-col xl:flex-row'}
                >
                  {getReleaseDate(detailObj?.release_time)}
                </p>
              </div>
            )}

            {Object.keys(detailObj.artists).map((artist, index) => (
              <div className={'flex mt-2'} key={index}>
                <p
                  style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                  className={'   text-sm md:text-lg w-4/12 lg:w-2/12'}
                >
                  {artist}
                </p>
                <div
                  style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9' }}
                  className={'  text-sm md:text-lg   w-8/12 lg:w-10/12 flex flex-col  xl:flex-row'}
                >
                  {detailObj.artists[artist].map((item, index) => (
                    <p
                      key={index}
                      onClick={() => navigate(`/artist/detail/${item.id}`)}
                      className={'cursor-pointer hover:underline'}
                    >
                      {index !== 0 && <span className={'hidden xl:contents'}>{' , '}</span>}
                      {item.name}
                    </p>
                  ))}
                </div>
              </div>
            ))}

            {!isEmpty(detailObj.venues) && (
              <div className={'flex mt-2'}>
                <p
                  style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                  className={'  text-sm md:text-lg w-4/12 lg:w-2/12'}
                >
                  {t('venue')}
                </p>
                <div
                  style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9' }}
                  className={'  text-sm md:text-lg  w-8/12 lg:w-10/12 flex flex-col xl:flex-row'}
                >
                  {detailObj.venues.map((item, index) => (
                    <p
                      key={index}
                      onClick={() => navigate(`/venue/detail/${item.id}`)}
                      className={'cursor-pointer hover:underline'}
                    >
                      {index !== 0 && <span className={'hidden xl:contents'}>{' , '}</span>}
                      {item.name}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {!isEmpty(detailObj.sub_category) && (
              <div className={'flex mt-2'}>
                <p
                  style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                  className={'  text-sm md:text-lg w-4/12 lg:w-2/12'}
                >
                  {t('genre')}
                </p>
                <div
                  style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9' }}
                  className={' text-sm md:text-lg  w-8/12 lg:w-10/12 flex flex-col xl:flex-row'}
                >
                  {detailObj.sub_category.map((item, index) => (
                    <p
                      key={index}
                      onClick={() => handleSubCategory(item.id, item.name)}
                      className={'cursor-pointer hover:underline'}
                    >
                      {index !== 0 && <span className={'hidden xl:contents'}>{' , '}</span>}
                      {item.name}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {detailObj.custom_metadata &&
              detailObj.custom_metadata.map((item, index) => (
                <div className={'flex mt-2'} key={index}>
                  <p
                    style={{ color: isCustomTemplate ? theme.c4 : '#b2b2b2' }}
                    className={'  text-sm md:text-lg w-4/12 lg:w-2/12'}
                  >
                    {item.name}
                  </p>
                  <div
                    style={{ color: isCustomTemplate ? theme.c3 : '#09e9e9' }}
                    className={'  text-sm md:text-lg w-8/12 lg:w-10/12 flex flex-col xl:flex-row'}
                  >
                    {item.value.split(',').map((obj, index) => (
                      <p
                        key={index}
                        onClick={() => handleCustomMetadata(item.id, obj, item.name)}
                        className={'cursor-pointer  hover:underline'}
                      >
                        {index !== 0 && <span className={'hidden xl:contents'}>{' , '}</span>}
                        {obj}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  )
}

export default withTheme(PlayerPageAbout)
