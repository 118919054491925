import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app'
import { withTheme } from 'theming'
import { FaRegKeyboard } from 'react-icons/fa'
import { GrEmoji } from 'react-icons/gr'
import Picker from 'emoji-picker-react'
import { useTranslation } from 'react-i18next'
import SendIcon from '../../assets/send-icon-blue.svg'
import { getRandomBgGradient } from '../../helper'

const CommentInput = (props) => {
  const { theme, handleSubmit, clearInput } = props
  const [inputText, setInputText] = useState('')
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)
  const { isCustomTemplate, userObj } = useContext(AppContext)
  const { t } = useTranslation()
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputText) {
      handleSubmit(inputText)
    }
  }

  useEffect(() => {
    if (clearInput) {
      setInputText('')
    }
  }, [clearInput])

  const onEmojiClick = (event, emojiObject) => {
    setInputText((inputText) => inputText.concat(emojiObject.emoji))
  }

  return (
    <>
      <div className="comment-box-wrapperq">
        <div className={'flex align-middle mt-2 mb-2 gap-x-5'} style={{ alignItems: 'center' }}>
          <div
            style={{ color: '#060606' }}
            className="w-6 h-6 md:h-8 md:w-8 font-semibold flex items-center justify-center rounded-md relative"
          >
            <img
              src={getRandomBgGradient(userObj.name)}
              className="w-full h-full"
              alt="getRandomBgGradient"
            />
            <p className="absolute text-sm md:text-base">
              {Array.from(`${userObj.name}`.toUpperCase())[0]}
            </p>
          </div>
          {/* <img src={ProfileIcon} alt="" className="w-10 h-10" /> */}
          <div className="bg-[#0c121a] w-full h-10 rounded-3xl flex justify-end items-center ">
            <input
              type="text"
              className="bg-inherit w-full rounded-3xl h-full  px-6 focus:outline-none
              "
              placeholder="Write a comment …"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e)}
            />
            <span className=" inset-y-0 flex items-center mx-3">
              {emojiPickerOpen ? (
                <FaRegKeyboard
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="h-6 w-6 opacity-80 hover:opacity-100 cursor-pointer"
                  onClick={() => setEmojiPickerOpen(false)}
                />
              ) : (
                <GrEmoji
                  style={{ color: isCustomTemplate ? theme.c4 : '#fff' }}
                  className="h-6 w-6 opacity-80 hover:opacity-100 cursor-pointer"
                  onClick={() => setEmojiPickerOpen(true)}
                />
              )}
            </span>
          </div>

          <span className="inset-y-0 flex items-center mr-2">
            <img
              src={SendIcon}
              alt="SendIcon"
              className="h-6 w-6 cursor-pointer"
              onClick={() => handleSubmit(inputText)}
            />
          </span>
        </div>
      </div>
      {emojiPickerOpen && (
        <div className={`h-[150px] md:h-[200px] lg:h-[250px] xl:h-[250px]`}>
          <Picker
            pickerStyle={{
              width: '100%',
              height: '100%',
              marginTop: '10px',
              boxShadow: 'none',
              borderRadius: '3px',
            }}
            onEmojiClick={onEmojiClick}
            disableSearchBar={true}
            groupNames={{
              smileys_people: t('people_emoji'),
              animals_nature: t('animals_emoji'),
              food_drink: t('food_emoji'),
              travel_places: t('travel_emoji'),
              activities: t('activities_emoji'),
              objects: t('objects_emoji'),
              symbols: t('symbols_emoji'),
              flags: t('flags_emoji'),
              recently_used: t('recently_used'),
            }}
            disableAutoFocus={true}
          />
        </div>
      )}
    </>
  )
}

export default withTheme(CommentInput)
