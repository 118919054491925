import React, { useContext, useEffect } from 'react'
import { Route, Routes, useLocation, useSearchParams, Navigate } from 'react-router-dom'
import { AppContext } from '../context/app'
import Header from '../components/header'
import Footer from '../components/footer'
import Home from '../container/home'
import ContentDetail from '../container/contentDetail/contentDetail'
import Player from '../container/player/player'
import Faq from '../container/staticPages/faq'
import Search from '../container/search'
import Favorites from '../container/favorite'
import Watchlist from '../container/watchlist'
import ProfilePage from '../container/profile'
import PaymentPage from '../container/paymentPage'
import ContentListing from '../container/contentListing'
import Subscription from '../components/subscription'
import { SubscriptionContext } from '../context/subscription'
import LoginRegister from '../components/auth/loginRegister'
import CategoryListingView from '../container/categoryView'
import CommonStaticPage from '../container/staticPages/CommonStaticPage'
import PaymentStatus from '../container/paymentPage/paymentStatus'
import MultiPartDetail from '../container/multiPartDetail/multiPartDetail'
import ArtistListing from '../container/artist/artistListing'
import VenueListing from '../container/venue/venueListing'
import ArtistDetail from '../container/artist/artistDetail'
import VenueDetail from '../container/venue/venueDetail'
import MyTransactions from '../container/myPlans/myTransactions'
import MySubscriptions from '../container/myPlans/mySubscriptions'
import LanguageModal from '../components/multiLanguage/languageModal'
import { Helmet } from 'react-helmet'
import { getImage } from '../helper'
import ResetPassword from '../container/reset-password'
import ForgotPassword from '../components/auth/forgotPassword'
import AccountDisable from '../components/accountDisabled'
import Signup from '../container/loginSignup/signup'
import Login from '../container/loginSignup/login'
import TemplateHome from '../container/template'
import defaultTheme from '../components/themes/defaultTheme'
import magentaHighlight from '../components/themes/magentaHighlight'
import neonDark from '../components/themes/neonDark'
import pantoneRed from '../components/themes/pantoneRed'
import darkInfraRed from '../components/themes/darkInfraRed'
import greenEnergy from '../components/themes/greenEnergy'
import { TemplateContext } from '../context/template'
import { ThemeProvider } from 'theming'
import OpaqueHeader from '../components/template/rosemary/opaqueHeader'
import ParallelFooter from '../components/template/rosemary/parallelFooter'
import CrystallineHeader from '../components/template/fitness/crystallineHeader'
import ConventionalFooter from '../components/template/fitness/conventionalFooter'
import ShadowBGFooter from '../components/template/gaming/shadowBGFooter'
import FullScreenLoader from '../components/fullscreenLoader'
import UIMediaHeader from '../components/template/uimedia/uiMediaHeader'
import UImediaFooter from '../components/template/uimedia/uimediaFooter'
import PlaylistHome from '../container/playlist'
import IFrameVideo from '../container/contentDetail/IFrameVideo'
import IframeModal from '../components/iframeModal'
import ATGHeader from '../components/template/atg/atgHeader'
import DefaultFooter from '../components/template/default/defaultFooter'
import { isEmpty } from 'lodash'
import VerifyEmailStatus from '../container/profile/verifyEmail'
import CustomPage from '../container/customPage/customPage'
import Channel from '../container/channel'
import ChannelListing from '../container/channel/channelListing'
import CustomForm from '../components/customForm'
import MiamiFoxFooter from '../components/template/miamiFox/miamiFoxFooter'
import MiamiFoxHeader from '../components/template/miamiFox/miamiFoxHeader'
import PlayerPage from '../container/playerPage'

const themes = [defaultTheme, magentaHighlight, neonDark, pantoneRed, darkInfraRed, greenEnergy]

const Routing = () => {
  const {
    isLogin,
    openLoginModal,
    loader,
    languageModal,
    accountObj,
    openForgotPasswordModal,
    isAccountInactive,
    isCustomTemplate,
    popupModalLink,
  } = useContext(AppContext)
  const { componentData, themeIndex, templateLoader } = useContext(TemplateContext)
  const [searchParams] = useSearchParams()
  const { openSubscriptionModal } = useContext(SubscriptionContext)

  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const isIFrame = () => location.pathname.includes('embed')
  const getHeaderFooter = (type) => {
    if (templateLoader || isEmpty(accountObj)) {
      return
    }

    if (isCustomTemplate) {
      let customComponentObj = componentData.find((info, index) =>
        info?.data?.component_key.includes(type),
      )
      if (customComponentObj) {
        let componentProps = {
          componentData: customComponentObj?.data,
          modifyData: () => {},
          setModalState: () => {},
          isEdit: false,
          onSwap: () => {},
          onDelete: () => {},
          onCopy: () => {},
        }
        let componentObj = {
          opaqueHeader: <OpaqueHeader {...componentProps} />,
          parallelFooter: <ParallelFooter {...componentProps} />,
          crystallineHeader: <CrystallineHeader {...componentProps} />,
          conventionalFooter: <ConventionalFooter {...componentProps} />,
          shadowBGFooter: <ShadowBGFooter {...componentProps} />,
          uiMediaHeader: <UIMediaHeader {...componentProps} />,
          UIMediaFooter: <UImediaFooter {...componentProps} />,
          atgHeader: <ATGHeader {...componentProps} />,
          defaultFooter: <DefaultFooter {...componentProps} />,
          miamiFoxHeader: <MiamiFoxHeader {...componentProps} />,
          miamiFoxFooter: <MiamiFoxFooter {...componentProps} />,
        }
        return componentObj[customComponentObj?.data?.component_key]
      }
      return
    }
    let basicComponentObj = {
      Header: <Header />,
      Footer: <Footer />,
    }
    return basicComponentObj[type]
  }

  return (
    <div
      className={`flex flex-col ${isIFrame() ? 'min-h-max' : 'min-h-screen'}`}
      style={{ background: themes[themeIndex].c1 }}
    >
      <ThemeProvider theme={themes[themeIndex]}>
        <FullScreenLoader
          loading={loader}
          color={templateLoader ? '#000' : 'rgba(60, 60, 60, 0.6)'}
        />
        {searchParams.get('mobileApp') !== 'true' && !isIFrame() && getHeaderFooter('Header')}
        <Helmet>
          <title>{`${
            accountObj?.account_metadata ? accountObj?.account_metadata?.title : 'title'
          }`}</title>
          <meta
            name="description"
            content={`${accountObj?.account_metadata && accountObj?.account_metadata?.description}`}
          />
          <link
            rel="icon"
            type="image/png"
            href={
              accountObj?.cf_domain_name &&
              getImage(accountObj?.cf_domain_name, 'public/static/favicon.png')
            }
            sizes="16x16"
          />
        </Helmet>
        <main className="flex-grow">
          <Routes>
            {!isAccountInactive ? (
              <>
                <Route path="/" element={isCustomTemplate ? <TemplateHome /> : <Home />} />
                <Route path="home" element={isCustomTemplate ? <TemplateHome /> : <Home />} />
                <Route path="content/:contentId" element={<ContentDetail />} />
                <Route path="embed/:contentId" element={<IFrameVideo />} />
                <Route path="parent-content/:contentId" element={<MultiPartDetail />} />
                <Route path="category/:categoryId" element={<CategoryListingView />} />
                <Route path="content/filter/:filterName" element={<ContentListing />} />
                <Route path="watch/:contentId" element={<Player />} />
                <Route path="about-us" element={<CommonStaticPage />} />
                <Route path="term-of-use" element={<CommonStaticPage />} />
                <Route path="privacy-policy" element={<CommonStaticPage />} />
                <Route path="copyright" element={<CommonStaticPage />} />
                <Route path="faq" element={<Faq />} />
                <Route path="search" element={<Search />} />
                <Route path="artists" element={<ArtistListing />} />
                <Route path="venues" element={<VenueListing />} />
                <Route path="channels" element={<ChannelListing />} />
                <Route path="artist/detail/:artistId" element={<ArtistDetail />} />
                <Route path="venue/detail/:venueId" element={<VenueDetail />} />
                <Route path="playlist/:playlistId" element={<PlaylistHome />} />
                <Route path="channel/:channelId" element={<Channel />} />
                <Route path="custom/:pageURL" element={<CustomPage />} />
                <Route path="static/:pageURL" element={<CustomPage />} />
                <Route
                  path="player/:contentId"
                  element={accountObj?.od_resource_allowances?.player_page ? <PlayerPage /> : <></>}
                />
                {!isLogin && (
                  <>
                    <Route path="reset-password/:resetKey" element={<ResetPassword />} />
                    <Route path="signup" element={<Signup />} />
                    <Route path="login" element={<Login />} />
                  </>
                )}
                {isLogin && (
                  <>
                    <Route path="favourites" element={<Favorites />} />
                    <Route path="watchlist" element={<Watchlist />} />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="transactions" element={<MyTransactions />} />
                    <Route path="subscriptions" element={<MySubscriptions />} />
                    <Route path="payment/:subscriptionId" element={<PaymentPage />} />
                    <Route path="payment-status/:data" element={<PaymentStatus />} />
                    <Route path="verify-email/:data" element={<VerifyEmailStatus />} />
                  </>
                )}
                {/*<Route path="*" element={<Navigate to="/home" replace />} />*/}
              </>
            ) : (
              <Route path="*" element={<AccountDisable />} />
            )}
          </Routes>
        </main>
        {openSubscriptionModal && <Subscription />}
        {openLoginModal && <LoginRegister />}
        {openForgotPasswordModal && <ForgotPassword />}
        {languageModal && <LanguageModal />}
        {popupModalLink && <IframeModal />}
        <CustomForm />
        {isCustomTemplate &&
          !isIFrame() &&
          location.pathname !== '/home' &&
          location.pathname !== '/' && <div className="md:py-10 py-4" />}
        {!isIFrame() && getHeaderFooter('Footer')}
      </ThemeProvider>
    </div>
  )
}

export default Routing
