import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/app'
import { BsHeart, BsHeartFill, BsPlusLg } from 'react-icons/bs'
import { MdOutlineLibraryAddCheck } from 'react-icons/md'
import TrailerPlayer from '../../container/player/trailerPlayer'
import { ContentContext } from '../../context/content'
import { getImage } from '../../helper'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'

const ContentCardHover = (props) => {
  const { isLogin, accountObj, isCustomTemplate } = useContext(AppContext)
  const { isInFavoriteList, isInWatchList, addRemoveFavouriteList, addRemoveWatchList } =
    useContext(ContentContext)

  const navigate = useNavigate()
  const { data, type, background, fontFamily, textColor, fontFormat, theme } = props

  const handleClick = () => {
    if (type === 'content') {
      if (data.video_type === 'PLAYLIST') {
        navigate(`/playlist/${data.content_id}`)
        return
      }
      if (data.video_type === 'MPVP') {
        navigate(`/parent-content/${data.content_id}`)
      } else {
        navigate(`/content/${data.content_id}`)
      }
    } else {
      if (type === 'artists') {
        navigate(`/artist/detail/${data._id}`)
      } else {
        navigate(`/venue/detail/${data._id}`)
      }
    }
  }

  return (
    <div
      style={{
        background: isCustomTemplate ? (background ? background : theme.c10) : '#1b242f',
        borderColor: isCustomTemplate ? (textColor ? textColor : theme.c4) : '',
      }}
      className="absolute rounded overflow-hidden shadow-xl transform ease transition duration-500 hover:scale-125 w-full z-50 border cursor-pointer"
    >
      <div className="h-1/2">
        <TrailerPlayer
          height={'11.5rem'}
          poster={getImage(accountObj?.cf_domain_name, data.thumbnail)}
          preview={data.preview}
          quality={360}
          type={'HOVER'}
          redirect={handleClick}
          videoType={data.video_type}
          stream={data.stream}
        />
      </div>
      <div
        style={{
          color: isCustomTemplate ? (textColor ? textColor : theme.c4) : '#fff',
          fontFamily: isCustomTemplate ? fontFamily : '',
        }}
        className="px-4 py-2"
      >
        <div className="mb-2 flex justify-between items-center ">
          <div className="">
            <p
              className={`text-base ${
                fontFormat ? fontFormat : 'font-bold'
              } leading-tight line-clamp-2`}
              onClick={handleClick}
            >
              {type === 'content' ? data.title : data.name}
            </p>
            {type === 'content' && !isEmpty(data?.content_rating) && (
              <div className="flex items-center mt-1">
                {data?.content_rating?.icon?.s3_key && (
                  <div className="mr-1">
                    <img
                      src={getImage(accountObj?.cf_domain_name, data?.content_rating?.icon?.s3_key)}
                      className="w-4 h-4"
                      alt="Rating"
                    />
                  </div>
                )}
                <p className="text-sm font-semibold line-clamp-1" style={{ color: '#1976d2' }}>
                  {data?.content_rating?.name}
                </p>
              </div>
            )}
          </div>

          {type === 'content' && isLogin && (
            <div className="flex w-16 justify-between">
              <div className="ml-2 cursor-pointer">
                {isInWatchList(data.content_id) ? (
                  <MdOutlineLibraryAddCheck
                    className="h-6 w-6"
                    onClick={() => addRemoveWatchList(data.content_id)}
                  />
                ) : (
                  <BsPlusLg
                    className="h-6 w-6"
                    onClick={() => addRemoveWatchList(data.content_id)}
                  />
                )}
              </div>
              <div className="cursor-pointer ml-4">
                {isInFavoriteList(data.content_id) ? (
                  <BsHeartFill
                    style={{ color: 'red' }}
                    className="h-6 w-6"
                    onClick={() => addRemoveFavouriteList(data.content_id)}
                  />
                ) : (
                  <BsHeart
                    className="h-6 w-6 "
                    onClick={() => addRemoveFavouriteList(data.content_id)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <p onClick={handleClick} className="text-xs line-clamp-2">
          {type === 'artists' ? data.bio : data.description}
        </p>
      </div>
    </div>
  )
}

export default withTheme(ContentCardHover)
