import { toast } from 'react-toastify'
import moment from 'moment'
import { DATE_FORMAT_OBJ } from './constants'
import OrangeIcon from '../src/assets/orange-gradient-box.svg'
import YellowIcon from '../src/assets/yellow-gradient-box.svg'
import PurpleIcon from '../src/assets/purpule-gradient-box.svg'
import MaroonIcon from '../src/assets/maroon-gradient-box.svg'
import BlueIcon from '../src/assets/blue-gradient-box.svg'
import PinkIcon from '../src/assets/pink-gradient-box.svg'

const DateFormat = localStorage.getItem('date_format')

export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export function viewsFormatter(num) {
  if (Math.abs(num) < 1000) return Math.sign(num) * Math.abs(num)
  if (Math.abs(num) < 1000000) return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K'

  return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + 'M'
}

export function secondsToHms(d) {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)

  var hDisplay = h > 0 ? h + ' h ' : ''
  var mDisplay = m > 0 ? m + ' min' : ''
  var sDisplay = s > 0 ? s + 's' : ''
  if (m >= 1 || h >= 1) {
    return hDisplay + mDisplay
  }
  return sDisplay
}

export function unixTimeToHumanReadable(seconds) {
  if (!seconds) {
    return '----'
  }
  let ans = ''

  // Number of days in month
  // in normal year
  let daysOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  let monthsArr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  let currYear,
    daysTillNow,
    extraDays,
    index,
    date,
    month,
    flag = 0

  // Calculate total days unix time T
  daysTillNow = parseInt(seconds / (24 * 60 * 60), 10)
  // extraTime = seconds % (24 * 60 * 60);
  currYear = 1970

  // Calculating current year
  while (daysTillNow >= 365) {
    if (currYear % 400 === 0 || (currYear % 4 === 0 && currYear % 100 !== 0)) {
      daysTillNow -= 366
    } else {
      daysTillNow -= 365
    }
    currYear += 1
  }

  // Updating extradays because it
  // will give days till previous day
  // and we have include current day
  extraDays = daysTillNow + 1

  if (currYear % 400 === 0 || (currYear % 4 === 0 && currYear % 100 !== 0)) flag = 1

  // Calculating MONTH and DATE
  month = 0
  index = 0
  if (flag === 1) {
    while (true) {
      if (index === 1) {
        if (extraDays - 29 < 0) break

        month += 1
        extraDays -= 29
      } else {
        if (extraDays - daysOfMonth[index] < 0) {
          break
        }
        month += 1
        extraDays -= daysOfMonth[index]
      }
      index += 1
    }
  } else {
    while (true) {
      if (extraDays - daysOfMonth[index] < 0) {
        break
      }
      month += 1
      extraDays -= daysOfMonth[index]
      index += 1
    }
  }

  // Current Month
  if (extraDays > 0) {
    month += 1
    date = extraDays
  } else {
    if (month === 2 && flag === 1) date = 29
    else {
      date = daysOfMonth[month - 1]
    }
  }

  ans += date.toString()
  ans += ' '
  ans += monthsArr[month - 1]
  ans += ' '
  ans += currYear.toString()

  // Return the time
  return ans
}

export function raiseToast(type, message) {
  if (type === 'success') {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } else if (type === 'error') {
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } else if (type === 'warn') {
    toast.warn(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } else if (type === 'info') {
    toast.info(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

export const PAGE_SIZE = 20

export const getReleaseDate = (data) => {
  if (data) {
    return moment(new Date(data + 'Z')).format(
      DATE_FORMAT_OBJ[DateFormat] ? DATE_FORMAT_OBJ[DateFormat] : 'DD-MM-yyyy',
    )
  } else {
    return ''
  }
}

export const createSlug = (name = '') => {
  return name.toLowerCase().replace(/[^A-Z0-9]+/gi, '-')
}

export const getImage = (cfDomainUrl, s3Key) => {
  return cfDomainUrl + '/' + s3Key
}

export const getTrailerUrl = (cfDomainUrl, asset_id, quality) => {
  return cfDomainUrl + '/public/trailer/' + asset_id + '/hls/video/' + quality + '_index.m3u8'
}

export const getRandomWithOneExclusion = (lengthOfArray, indexToExclude) => {
  var random = null //an integer

  while (random === null || random === indexToExclude) {
    random = Math.round(Math.random() * (lengthOfArray - 1))
  }
  console.log('handlePlayingSequence random', random)
  return random
}

export const convertDuration = (seconds) => {
  if (seconds !== undefined) {
    seconds = parseInt(seconds)
    let minutes = parseInt(seconds / 60)
    if (seconds > 3600) {
      return Math.floor(minutes / 60) + ' hr ' + (minutes % 60) + ' min'
    } else if (seconds > 60) {
      return Math.floor(seconds / 60) + ' min ' + (seconds % 60) + ' sec'
    } else {
      return seconds + 'sec'
    }
  } else {
    return 'N/A'
  }
}

export const convertDurationLiveComment = (seconds) => {
  if (seconds !== undefined) {
    seconds = parseInt(seconds)
    if (seconds >= 31536000) {
      return `${Math.floor(seconds / 31536000)} ${
        Math.floor(seconds / 31536000) > 1 ? 'Years ago' : 'Year ago'
      }`
    } else if (seconds >= 2592000) {
      return `${Math.floor(seconds / 2592000)} ${
        Math.floor(seconds / 2592000) > 1 ? 'Months ago' : 'Month ago'
      }`
    } else if (seconds >= 86400) {
      return `${Math.floor(seconds / 86400)} ${
        Math.floor(seconds / 86400) > 1 ? 'Days ago' : 'Day ago'
      }`
    } else if (seconds >= 3600) {
      return `${Math.floor(seconds / 3600)} ${
        Math.floor(seconds / 3600) > 1 ? 'Hours ago' : 'Hour ago'
      }`
    } else if (seconds >= 60) {
      return `${Math.floor(seconds / 60)} ${
        Math.floor(seconds / 60) > 1 ? 'Minutes ago' : 'Minute ago'
      }`
    } else {
      return `${seconds} ${seconds > 1 ? 'Seconds ago' : 'Second ago'}`
    }
  } else {
    return 'N/A'
  }
}

export function getCharCodesCount(str) {
  if (!str) {
    return 0
  }
  let charCodeCount = 0

  for (let i = 0; i < str?.length; i++) {
    let code = str.charCodeAt(i)
    charCodeCount += code
  }

  return charCodeCount
}

export const getRandomBgGradient = (name) => {
  const bgImg = [
    YellowIcon,
    OrangeIcon,
    MaroonIcon,
    PurpleIcon,
    PinkIcon,
    BlueIcon,
    YellowIcon,
    PurpleIcon,
    OrangeIcon,
  ]

  let random = getCharCodesCount(name) % 9 || 9

  return bgImg[random]
}

export const convertToLocalTime = (date) => {
  if (!date) return ''

  return new Date(date + 'Z').toLocaleTimeString('en-UK', {
    timeStyle: 'short',
    hour12: false,
  })
}
